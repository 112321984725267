import React, { Component } from 'react';
import './app.scss';
import { Content } from '@carbon/react';
import { Route, Switch, BrowserRouter, Redirect } from 'react-router-dom';
import LandingPage from './content/LandingPage';
import RegisterEmailidMatched from './components/register-email-matched';
import RegisterEmailidNotmatched from './components/register-email-not-matched';
import RepoPage from './content/RepoPage';
import GuestLecture from './content/GuestLecture/GuestLecture';
import LectureRequest from './content/GuestLecture/LectureRequest';
import Workshop from './content/Workshop/Workshop';
import WorkshopRequest from './content/Workshop/WorkshopRequest';
import EmailAuth from './content/EmailAuth/email-auth';
import Register from './components/register/register';
import RegisterEdit from './components/register-edit/register-edit';
import ApprovalStatus from './components/approval-status';
import ValidateEmail from './components/validate-email';
import DeniedIBMer from './components/register/denied-ibmer';
import InvalidUniversity from './components/invalid-university';
import utilService from './services/util-service';
import UserCheck from './content/user-verification';
import SpssRequest from './content/Spss';
import SBSDAgreement from './content/SBSDAgreement';
import SBSDGuidelines from './content/SBSDGuidelines/sbsd-guidelines';
import SBSDPrivacy from './content/SBSDPrivacy';
import SBSDFaq from './content/SBSDFaq/sbsd-faq';
import AdminDashboard from './components/admin/admin-dashboard';
import Cookies from 'universal-cookie';
import factoryService from './services/factory-service';

const cookies = new Cookies();

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      auth: true,
    };
  }

  async componentDidMount() {
    if (typeof cookies.get('AppAccess') !== 'string') {
      await this.getAccess();
    }

    if (
      utilService.getOtherPageAccessible() === 'false' ||
      utilService.getOtherPageAccessible() === null
    ) {
      this.setState({ auth: false });
    }
  }

  async getAccess() {
    let acc = await factoryService.getAppID();
    cookies.set('AppAccess', acc);
  }

  render() {
    return (
      <BrowserRouter>
        <Content>
          <Switch>
            <Route exact path="/">
              <Redirect to="/a2mt/email-auth" />
            </Route>
            <Route exact path="/a2mt">
              <Redirect to="/a2mt/email-auth" />
            </Route>
            <Route exact path="/a2mt/email-auth" component={EmailAuth} />
            <Route exact path="/a2mt/user-check" component={UserCheck} />
            <Route exact path="/a2mt/downloads" component={LandingPage} />
            <Route exact path={'/a2mt/downloads/:id'} component={RepoPage} />
            <Route
              exact
              path={'/a2mt/downloads/data_science/spss-map'}
              component={SpssRequest}
            />
            {this.state.auth && (
              <Route exact path="/a2mt/register" component={Register} />
            )}
            {this.state.auth && (
              <Route
                exact
                path="/a2mt/register-edit"
                component={RegisterEdit}
              />
            )}
            {this.state.auth && (
              <Route exact path="/a2mt/denied-ibmer" component={DeniedIBMer} />
            )}
            {this.state.auth && (
              <Route
                exact
                path="/a2mt/invalid-university"
                component={InvalidUniversity}
              />
            )}
            <Route
              exact
              path="/a2mt/register-email-matched"
              component={RegisterEmailidMatched}
            />
            <Route
              exact
              path="/a2mt/register-email-not-matched"
              component={RegisterEmailidNotmatched}
            />
            <Route
              exact
              path="/a2mt/approval-status"
              component={ApprovalStatus}
            />
            <Route exact path="/a2mt/validate" component={ValidateEmail} />
            <Route exact path="/a2mt/lectures" component={GuestLecture} />
            <Route exact path="/a2mt/events" component={Workshop} />
            {this.state.auth && (<Route exact path="/a2mt/event-request" component={WorkshopRequest} />)}
            <Route exact path="/a2mt/agreement" component={SBSDAgreement} />
            <Route exact path="/a2mt/guidelines" component={SBSDGuidelines} />
            <Route exact path="/a2mt/privacy" component={SBSDPrivacy} />
            <Route exact path="/a2mt/faq" component={SBSDFaq} />
            {this.state.auth && (
              <Route
                exact
                path="/a2mt/lectures-request"
                component={LectureRequest}
              />
            )}

            <Route
              exact
              path="/a2mt/admin-dashboard"
              component={AdminDashboard}
            />

            <Route
              exact
              path="/a2mt/admin-dashboard/:id"
              component={AdminDashboard}
            />

            {!this.state.auth && <Redirect to="/a2mt/email-auth" />}
          </Switch>
        </Content>
      </BrowserRouter>
    );
  }
}

export default App;
