import axios from "axios";
import Cookies from 'universal-cookie';
const cookies = new Cookies();

// const prodLink = "https://www.ibm.com/academic/";
// const nonProdLink = "https://wwwpoc.ibm.com/academic/";
 
const utilService = {
  hostname: "https://academic.ibm.com",
  copyLINK: "https://academic.ibm.com/a2mt/",

  setBaseURL() {
    // if (window.location.href.includes('academic-prod') || window.location.href.includes('multi-container'))
    //   window.location = prodURL + '/a2mt/';
    // const org = window.location.origin; console.log('org', org);
    // if (org.indexOf('academic') !== -1) { 
    // this.hostname = org;
    this.hostname = 'https://academic.ibm.com'
    // } 
    // else if (org.indexOf('uat') !== -1) { 
    //   this.hostname = uatURL 
    // }
    // else { 
    //   this.hostname = devURL 
    // }
  },

  getData(url) {
    return axios
      .get(this.hostname + url, {
        headers: { 'Jwtauth': cookies.get('Jwtauth', { doNotParse: true }) }
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error;
      });
  },

  postData(url, param) {
    let header = { 'Jwtauth': cookies.get('Jwtauth', { doNotParse: true }) };
    return axios
      .post(
        this.hostname + url,
        param,
        { headers: header }
      )
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error;
      });
  },

  setOtherPageAccessible(isOtherPageAccessible) {
    sessionStorage.setItem("isOtherPageAccessible", isOtherPageAccessible);
  },

  getOtherPageAccessible() {
    return sessionStorage.getItem("isOtherPageAccessible");
  },

  setFrontEndLink () {
    // const org = window.location.origin;
    this.copyLINK = "https://academic.ibm.com/a2mt/events?ws=";
    // if (org.indexOf('prod') !== -1) {
    //   this.copyLINK = prodLink + "events/skills-academy?ws=";
    // } else {
    //   this.copyLINK = nonProdLink + "events/skills-academy?ws=";
    // }
  }
}

export default utilService;