import {
  Grid,
  Column,
  Button,
  DataTable,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
  Loading,
  TableContainer,
  TableSelectRow,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
  TextInput,
  Modal,
  Select,
  SelectItem,
} from '@carbon/react';
import { WarningAlt, MisuseOutline } from '@carbon/icons-react';
import { Add, Edit, CheckmarkOutline, TrashCan } from '@carbon/icons-react';
import React from 'react';
import Axios from 'axios';
import './institute-management.scss';
import utilService from '../../../services/util-service';

const domainNameRegex = RegExp(
  /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/
);

export default class InstituteManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      baseURL: utilService.hostname,
      emailId: localStorage.getItem('adminEmail'),
      showLoader: false,
      showAlert: false,
      alertMsg: 'Test',
      alertType: 'Success',
      labelAdd: (
        <div>
          Enter Academic Institution Domain:<span className="error">*</span>{' '}
          (Example: ibm.com)
        </div>
      ),
      isEditOverlayVisible: false,
      isAddOverlayVisible: false,
      isDeleteOverlayVisible: false,
      editDataset: {},
      rowData: [],
      headerData: [{ header: 'Domain', key: 'domainName' }],
      selectedRowData: null,
      invalidDomainName: false,
      domainName: null,
      domainListPrimary: [],
      regionsList: [],
      instituteList: [],
      domainList: [],
      isDatatableShow: false,
      accessTokn: process.env.REACT_APP_X_ACCESS,
    };
    console.log('Institution management loaded');
  }

  sort_by_key(array, key) {
    return array.sort(function(a, b) {
      var x = a[key].trim();
      var y = b[key].trim();
      return x < y ? -1 : x > y ? 1 : 0;
    });
  }

  componentDidMount() {
    document.getElementById('select-institute').disabled = true;
    // this.getAllEditProgramList();
    this.getAllRegionsList();
  }
  componentDidUpdate() {
    sessionStorage.removeItem('isOtherPageAccessible');
    utilService.setOtherPageAccessible(false);
  }

  getAllRegionsList() {
    this.setState({ showLoader: true });
    Axios.get(this.state.baseURL + '/a2mt/services/user-details/getCountry', {
      headers: {
        'Content-Type': 'application/json',
        'X-access': this.state.accessTokn,
      },
    })
      .then(res => {
        this.setState({ showLoader: false });
        console.log(res.data);
        if (res.data != null) {
          this.setState({ regionsList: res.data });
        } else {
          this.setState({ regionsList: [] });
        }
      })
      .catch(error => {
        this.setState({ showLoader: false });
        this.setState({
          alertMsg: 'Something went wrong. Please try again.',
          alertType: 'Error',
          showAlert: true,
        });
      });
  }
  getAllInstitutionsList(value) {
    this.setState({ showLoader: true });
    utilService
      .getData('/a2mt/services/user-details/getAllInsttns/' + value)
      .then(res => {
        this.setState({ showLoader: false });
        console.log(res.data);
        this.sort_by_key(res.data, 1);
        if (res.data != null) {
          this.setState({ instituteList: res.data });
        } else {
          this.setState({ instituteList: [] });
        }
      })
      .catch(error => {
        this.setState({ showLoader: false });
        this.setState({
          alertMsg: 'Something went wrong. Please try again.',
          alertType: 'Error',
          showAlert: true,
        });
      });
  }
  getAllInstitutionDomainsList() {
    this.setState({ showLoader: true });
    let sel = document.getElementById('select-institute');
    let instID = sel.value;
    utilService
      .getData(
        '/a2mt/services/user-details/getAllInsttnDOmainsForInsttnSupMerge/' +
          instID
      )
      .then(res => {
        this.setState({ showLoader: false });
        console.log(res.data);
        if (res.data != null) {
          console.log(res.data[1]);
          let arrdomainList = [];
          res.data[1].map(item => {
            let objDomainList = {
              id: item[0],
              domainName: item[1],
            };
            arrdomainList.push(objDomainList);
          });
          this.setState({
            domainList: arrdomainList,
            domainListPrimary: res.data[0],
          });
        } else {
          this.setState({ domainList: [], domainListPrimary: [] });
        }
      })
      .catch(error => {
        this.setState({ showLoader: false });
        this.setState({
          alertMsg: 'Something went wrong. Please try again.',
          alertType: 'Error',
          showAlert: true,
        });
      });
  }

  onSelectRegInsChange = event => {
    if (event.target.name == 'regionSelect') {
      this.getAllInstitutionsList(event.target.value);
      document.getElementById('select-institute').disabled = false;
      document.getElementById('select-institute').value = 'placeholder-item';
      this.setState({ isDatatableShow: false });
    } else if (event.target.name == 'instituteSelect') {
      this.getAllInstitutionDomainsList();
      this.setState({ isDatatableShow: true });
    }
  };

  editSelectedRow = event => {
    let sel = this.state.selectedRowData;
    console.log(sel);
    if (sel !== null) {
      let record = this.state.domainList.filter(e => e.id === sel.id);
      if (record.length > 0) {
        this.setState({ editDataset: record[0], isEditOverlayVisible: true });
        console.log('record', record[0]);
        document.getElementById('editDomain').value = record[0].domainName;
      } else {
        this.setState({ editDataset: {} });
      }
    } else {
      this.setState({
        alertMsg: 'Please select a row to edit!',
        alertType: 'Warning',
        showAlert: true,
      });
    }
  };
  deleteSelectedRow = event => {
    let sel = this.state.selectedRowData;
    console.log(sel);
    if (sel !== null) {
      let record = this.state.domainList.filter(e => e.id === sel.id);
      if (record.length > 0) {
        this.setState({ editDataset: record[0], isDeleteOverlayVisible: true });
        console.log('record', record[0]);
      } else {
        this.setState({ editDataset: {} });
      }
    } else {
      this.setState({
        alertMsg: 'Please select a row to delete!',
        alertType: 'Warning',
        showAlert: true,
      });
    }
  };

  addNewRow = event => {
    this.setState({ isAddOverlayVisible: true });
  };

  onTextChange = event => {
    let id = event.target.id,
      vl = event.target.value;
    console.log('onTextChange', id, vl);
    if (id == 'addDomain' && vl.trim() !== '') {
      domainNameRegex.test(vl)
        ? this.setState({ domainName: vl.trim(), invalidDomainName: false })
        : this.setState({ domainName: null, invalidDomainName: true });
    }

    if (id == 'editDomain' && vl.trim() !== '') {
      if (domainNameRegex.test(vl.trim())) {
        this.setState(prevState => ({
          editDataset: { ...prevState.editDataset, domainName: vl.trim() },
        }));
        this.setState({ invalidDomainName: false });
      } else {
        this.setState({ invalidDomainName: true });
      }
    } else if (id == 'editDomain' && vl.trim() == '') {
      console.log('test', event.target.value);
      this.setState({ invalidDomainName: true });
    }
  };

  addDomainSubmit = event => {
    let addFormValid = true;
    if (this.state.domainName == null) {
      this.setState({ invalidDomainName: true });
      addFormValid = false;
    }
    if (addFormValid) {
      console.log('valid form');
      let sel = document.getElementById('select-institute');
      let instID = sel.value;
      let instNm = sel.options[sel.selectedIndex].text;
      let data = {
        addedBy: localStorage.getItem('adminEmail'),
        insttnName: instNm,
        insttn_id: instID,
        universityDomain: this.state.domainName,
      };
      console.log('postData', data);
      // return false;
      this.setState({ showLoader: true });
      this.closeAllOverlays();
      utilService
        .postData('/a2mt/services/user-details/addInsttnSup', data)
        .then(rs => {
          document.getElementById('addDomain').value = '';
          console.log(rs.data);
          this.setState({ showLoader: false });
          this.resetForm();
          this.getAllInstitutionDomainsList();
          if (rs.data.success === true) {
            this.setState({
              alertMsg: 'Domain is successfully added.',
              alertType: 'Success',
              showAlert: true,
            });
          } else if (rs.data.success === false && rs.data.val == 'Duplicate') {
            this.setState({
              alertMsg:
                'Save failed. Domain already exists. Duplicate records not allowed.',
              alertType: 'Warning',
              showAlert: true,
            });
          } else {
            this.setState({
              alertMsg: 'Save failed. Please try again later.',
              alertType: 'Warning',
              showAlert: true,
            });
          }
        })
        .catch(error => {
          document.getElementById('addDomain').value = '';
          this.setState({ showLoader: false });
          this.resetForm();
          this.setState({
            alertMsg: 'Something went wrong. Please try again.',
            alertType: 'Error',
            showAlert: true,
          });
        });
    }
  };

  editDomainSubmit = event => {
    if (!this.state.invalidDomainName) {
      console.log('valid form');
      let data = this.state.editDataset;
      let editdata = {
        addedBy: localStorage.getItem('adminEmail'),
        id: data.id,
        universityDomain: data.domainName,
      };
      console.log('editdata', editdata);
      //return false;
      this.setState({ showLoader: true });
      this.closeAllOverlays();
      utilService
        .postData(
          '/a2mt/services/user-details/updateInsttnSup/' + data.id,
          editdata
        )
        .then(rs => {
          console.log(rs.data);
          this.setState({ showLoader: false });
          this.resetForm();
          this.getAllInstitutionDomainsList();
          if (rs.data.success === true) {
            this.setState({
              alertMsg: 'Domain is successfully updated.',
              alertType: 'Success',
              showAlert: true,
            });
          } else if (rs.data.success === false && rs.data.val == 'Duplicate') {
            this.setState({
              alertMsg:
                'Update failed. Domain already exists. Duplicate records not allowed.',
              alertType: 'Warning',
              showAlert: true,
            });
          } else {
            this.setState({
              alertMsg: 'Update failed. Please try again later.',
              alertType: 'Error',
              showAlert: true,
            });
          }
        })
        .catch(error => {
          this.setState({ showLoader: false });
          this.resetForm();
          this.setState({
            alertMsg: 'Something went wrong. Please try again.',
            alertType: 'Error',
            showAlert: true,
          });
        });
    }
  };

  deleteDomainSubmit = event => {
    let data = this.state.editDataset;
    this.setState({ showLoader: true });
    this.closeAllOverlays();
    Axios.delete(
      this.state.baseURL +
        '/a2mt/services/user-details/deleteInsttnSup/' +
        data.id,
      {
        headers: {
          'Content-Type': 'application/json',
          'X-access': this.state.accessTokn,
        },
      }
    )
      .then(rs => {
        console.log(rs.data);
        this.setState({ showLoader: false });
        this.resetForm();
        this.getAllInstitutionDomainsList();
        this.setState({
          alertMsg: 'Domain deleted successfully',
          alertType: 'Success',
          showAlert: true,
        });
      })
      .catch(error => {
        this.setState({ showLoader: false });
        this.resetForm();
        this.setState({
          alertMsg: 'Something went wrong. Please try again.',
          alertType: 'Error',
          showAlert: true,
        });
      });
  };
  closeAllOverlays = event => {
    this.setState({
      isAddOverlayVisible: false,
      isEditOverlayVisible: false,
      isDeleteOverlayVisible: false,
    });
  };

  resetForm() {
    this.setState({
      invalidDomainName: false,
      domainName: null,
      editDataset: {},
    });
  }

  render() {
    return (
      <div className="body-height">
        <Grid className="admin-sub-page">
          <Column lg={{ span: 12, offset: 4 }} md={8} sm={4}>
            <div>
              <div style={{ paddingBottom: '32px' }}>
                <h3>Institution Management</h3>
              </div>
              <section>
                <div style={{ marginBottom: '2rem' }}>
                  <div className="bx--grid--full-width">
                    <div className="bx--row">
                      <div className="bx--col">
                        <Select
                          id="select-regions"
                          defaultValue="placeholder-item"
                          labelText="Select Country / Region"
                          invalidText="Please choose an option"
                          name="regionSelect"
                          onChange={this.onSelectRegInsChange}>
                          <SelectItem
                            disabled
                            hidden
                            value="placeholder-item"
                            text="Choose an option"
                          />
                          {this.state.regionsList.map(val => (
                            <SelectItem value={val[0]} text={val[1]} />
                          ))}
                        </Select>
                      </div>
                      <div className="bx--col">
                        <Select
                          id="select-institute"
                          defaultValue="placeholder-item"
                          labelText="Select Institute"
                          invalidText="Please choose an option"
                          name="instituteSelect"
                          onChange={this.onSelectRegInsChange}>
                          <SelectItem
                            value="placeholder-item"
                            text="Choose an option"
                          />
                          {this.state.instituteList.map(val => (
                            <SelectItem value={val[0]} text={val[1]} />
                          ))}
                        </Select>
                      </div>
                    </div>
                  </div>
                </div>
                {this.state.isDatatableShow && (
                  <DataTable
                    rows={this.state.domainList}
                    headers={this.state.headerData}
                    isSortable
                    radio>
                    {({
                      rows,
                      headers,
                      getHeaderProps,
                      getRowProps,
                      getSelectionProps,
                      getTableProps,
                      getTableContainerProps,
                      onInputChange,
                      selectedRows,
                    }) => (
                      <TableContainer {...getTableContainerProps()}>
                        <TableToolbar>
                          <TableToolbarContent>
                            <TableToolbarSearch onChange={onInputChange} />
                            <Button
                              hasIconOnly
                              renderIcon={Edit}
                              iconDescription="Edit Domain"
                              kind="ghost"
                              onClick={this.editSelectedRow}
                              tooltipAlignment="center"
                              tooltipPosition="bottom"
                              id="edt-btn"
                            />
                            <Button
                              hasIconOnly
                              renderIcon={TrashCan}
                              iconDescription="Delete Domain"
                              kind="ghost"
                              onClick={this.deleteSelectedRow}
                              tooltipAlignment="center"
                              tooltipPosition="bottom"
                              id="edt-btn"
                            />
                            <Button
                              renderIcon={Add}
                              onClick={this.addNewRow}
                              id="add-btn">
                              Add Domain
                            </Button>
                          </TableToolbarContent>
                        </TableToolbar>
                        <Table {...getTableProps()}>
                          <TableHead>
                            <TableRow>
                              <TableHeader />
                              {headers.map((header, i) => (
                                <TableHeader
                                  key={i}
                                  {...getHeaderProps({ header })}>
                                  {header.header}
                                </TableHeader>
                              ))}
                            </TableRow>
                          </TableHead>

                          <TableBody>
                            {this.state.domainListPrimary.map(value => (
                              <TableRow>
                                <TableRow />
                                <TableCell key={value[0]}>{value[1]}</TableCell>
                              </TableRow>
                            ))}
                            {rows.map((row, i) => (
                              <TableRow key={i} {...getRowProps({ row })}>
                                <TableSelectRow
                                  {...getSelectionProps({
                                    row,
                                    onClick: () =>
                                      this.setState({ selectedRowData: row }),
                                  })}
                                />
                                {row.cells.map(cell => (
                                  <TableCell key={cell.id}>
                                    {cell.value}
                                  </TableCell>
                                ))}
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )}
                  </DataTable>
                )}
              </section>

              <Loading
                withOverlay={true}
                description="Active loading indicator"
                active={this.state.showLoader}
              />

              <Modal
                passiveModal={true}
                preventCloseOnClickOutside={false}
                open={this.state.showAlert}
                size="sm"
                onRequestClose={() => {
                  this.setState({ showAlert: false });
                }}>
                <div style={{ padding: '1em 0' }}>
                  {this.state.alertType == 'Success' && (
                    <div>
                      <CheckmarkOutline
                        style={{ width: '7em', height: '7em', color: 'green' }}
                      />
                    </div>
                  )}
                  {this.state.alertType == 'Warning' && (
                    <div>
                      <WarningAlt
                        style={{ width: '7em', height: '7em', color: 'orange' }}
                      />
                    </div>
                  )}
                  {this.state.alertType == 'Error' && (
                    <div>
                      <MisuseOutline
                        style={{ width: '7em', height: '7em', color: 'red' }}
                      />
                    </div>
                  )}
                  <div style={{ paddingLeft: '1em' }}>
                    {this.state.alertMsg}
                  </div>
                </div>
              </Modal>

              <Modal
                hasForm
                open={this.state.isAddOverlayVisible}
                primaryButtonText="Add"
                secondaryButtonText="Cancel"
                modalHeading="Add domain"
                onRequestClose={() => {
                  this.closeAllOverlays();
                  this.resetForm();
                }}
                onRequestSubmit={this.addDomainSubmit}>
                <div style={{ margin: '1em 0' }}>
                  <TextInput
                    id="addDomain"
                    labelText={this.state.labelAdd}
                    placeholder="Enter domain"
                    onChange={this.onTextChange}
                    invalid={this.state.invalidDomainName}
                    invalidText="Please enter valid domain name"
                  />
                </div>
              </Modal>

              <Modal
                hasForm
                open={this.state.isEditOverlayVisible}
                primaryButtonText="Update"
                secondaryButtonText="Cancel"
                modalHeading="Edit domain"
                onRequestClose={() => {
                  this.closeAllOverlays();
                  this.resetForm();
                }}
                onRequestSubmit={this.editDomainSubmit}>
                <div style={{ margin: '1em 0' }}>
                  <TextInput
                    id="editDomain"
                    labelText={this.state.labelAdd}
                    placeholder="Enter domain"
                    defaultValue={this.state.editDataset.domainName}
                    onChange={this.onTextChange}
                    invalid={this.state.invalidDomainName}
                    invalidText="Please enter valid domain name"
                  />
                </div>
              </Modal>
              <Modal
                hasForm
                open={this.state.isDeleteOverlayVisible}
                primaryButtonText="Yes"
                secondaryButtonText="No"
                modalHeading="Delete domain"
                onRequestClose={() => {
                  this.closeAllOverlays();
                  this.resetForm();
                }}
                onRequestSubmit={this.deleteDomainSubmit}>
                <div style={{ margin: '1em 0' }}>
                  <p>
                    Are you sure you want to delete this domain - "
                    {this.state.editDataset.domainName}" ?
                  </p>
                </div>
              </Modal>
            </div>
          </Column>
        </Grid>
      </div>
    );
  }
}
