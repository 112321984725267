import React from 'react';
import './SpssRequest.scss';

import {
  TextInput,
  Button,
  Select,
  SelectItem,
  Modal,
  TextArea,
  NumberInput,
  Theme,
  Grid,
  Column,
  Loading,
  Link
} from '@carbon/react';
import Axios from 'axios';
import { CheckmarkOutline } from '@carbon/icons-react';
import { WarningAlt, MisuseOutline } from '@carbon/react/icons';
import factoryService from '../../services/factory-service';
import NormalHeader from '../../components/NormalHeader/NormalHeader';
//import coreService from '../services/core-service';
export default class SpssRequest extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      emailID: '',
      usrID: 0,
      questions: null,
      yesNo: [],
      course: [],
      discipline: [],
      questionnaireData: null,
      answerValues: [],
      loggedin: false,
      showAlert: false,
      alertMsg: 'Test',
      alertType: 'Success',
      showRedirectAlert: false,
      jwtToken: null,
      loader: true,
    };

    this.onTextChange = this.onTextChange.bind(this);
    this.onSelectTypeChange = this.onSelectTypeChange.bind(this);
    this.onNumberChange = this.onNumberChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.closeAlert = this.closeAlert.bind(this);
    this.closeAlertAndRedirect = this.closeAlertAndRedirect.bind(this);

    //coreService.loaderVisible.next(true);
    this.setState({ loader: true });
    this.getJWT();
  }
  async getJWT() {
    await Axios.get(
      factoryService.source_a2mt + 'services/user-details/getJwt',
      { headers: { emailId: localStorage.getItem('userEmail') } }
    )
      .then(res => {
        console.log('Header Data', res);
        if (typeof res.headers['jwt-auth'] != 'undefined') {
          let token = res.headers['jwt-auth'];
          this.setState({ jwtToken: token });
          factoryService
            .fetchQuestions(token)
            .then(a => {
              //coreService.loaderVisible.next(false);
              this.setState({ loader: false });
              if (typeof a != 'undefined' && a != null && a != '') {
                let tempErrors = a.map(val => {
                  return { id: val.id, invalid: false, ans: '' };
                });
                this.setState({ answerValues: tempErrors, questions: a });
                this.getOptions(token).then(() => {
                  //coreService.loaderVisible.next(false);
                  this.setState({ loader: false });
                  console.log(
                    'optionData',
                    this.state.questions,
                    this.state.course,
                    this.state.yesNo,
                    this.state.discipline
                  );
                  this.checkUserLogin(this.state.questions);
                });
              }
            })
            .catch(err => {
              //coreService.loaderVisible.next(false);
              this.setState({ loader: false });
              console.log('ques error:', err);
              this.setState({
                alertMsg:
                  'Something went wrong! Please reload the page and try again.',
                alertType: 'Error',
                showAlert: true,
              });
            });
        }
      })
      .catch(error => { });
  }
  checkUserLogin(ques) {
    const getUserDet = async () => {
      const v = await factoryService.getUserDetails(
        factoryService.getSession()
      );
      let userData = factoryService.decodeData(v, factoryService.getSession());
      if (userData.email !== null && userData.role != 1) {
        this.setState({
          loggedin: true,
          emailID: userData.email,
          usrID: String(userData.id),
        });
        if (this.state.course.length > 0 && this.state.yesNo.length > 0) {
          this.setQuestions(ques);
        } else {
          this.setState({
            alertMsg:
              'Something went wrong! Please reload the page and try again.',
            alertType: 'Error',
            showAlert: true,
          });
        }
      } else {
        this.setState({
          loggedin: false,
          emailID: '',
          usrID: 0,
        });
        this.disableQuestions(ques);
      }
    };
    getUserDet();
  }

  async getOptions(token) {
    //coreService.loaderVisible.next(true);
    this.setState({ loader: true });
    await factoryService
      .fetchCourse(token)
      .then(b => {
        if (typeof b != 'undefined' && b != null) {
          this.setState({ course: b });
        } else {
          this.setState({
            alertMsg:
              'Something went wrong! Please reload the page and try again.',
            alertType: 'Error',
            showAlert: true,
          });
        }
      })
      .catch(e => {
        console.log('error', e);
        this.setState({
          alertMsg:
            'Something went wrong! Please reload the page and try again.',
          alertType: 'Error',
          showAlert: true,
        });
      });
    await factoryService
      .fetchYesNo(token)
      .then(c => {
        if (typeof c != 'undefined' && c != null) {
          this.setState({ yesNo: c });
        } else {
          this.setState({
            alertMsg:
              'Something went wrong! Please reload the page and try again.',
            alertType: 'Error',
            showAlert: true,
          });
        }
      })
      .catch(e => {
        console.log('error', e);
        this.setState({
          alertMsg:
            'Something went wrong! Please reload the page and try again.',
          alertType: 'Error',
          showAlert: true,
        });
      });
    await factoryService
      .fetchDiscipline(token)
      .then(d => {
        if (typeof d != 'undefined' && d != null) {
          d.sort((a, b) => {
            a = a.code.toLowerCase();
            b = b.code.toLowerCase();
            return a < b ? -1 : a > b ? 1 : 0;
          });
          this.setState({ discipline: d });
        } else {
          this.setState({
            alertMsg:
              'Something went wrong! Please reload the page and try again.',
            alertType: 'Error',
            showAlert: true,
          });
        }
      })
      .catch(e => {
        console.log('error', e);
        this.setState({
          alertMsg:
            'Something went wrong! Please reload the page and try again.',
          alertType: 'Error',
          showAlert: true,
        });
      });
  }

  onTextChange(event) {
    let nm = event.target.name,
      vl = event.target.value;
    if (vl.trim() !== '') {
      let answerValues = this.state.answerValues.map(el =>
        '' + el.id === nm.toString()
          ? { ...el, invalid: false, ans: vl.trim() }
          : el
      );
      this.setState({ answerValues: answerValues });
    } else {
      let answerValues = this.state.answerValues.map(el =>
        '' + el.id === nm.toString() ? { ...el, invalid: true, ans: '' } : el
      );
      this.setState({ answerValues: answerValues });
    }
    // console.log('txt', nm, vl.trim(), this.state.answerValues);
    setTimeout(() => {
      this.setQuestions(this.state.questions);
    }, 500);
  }

  onSelectTypeChange(event) {
    const { options, selectedIndex } = event.target;
    let answerValues = this.state.answerValues.map(el =>
      '' + el.id === event.target.name.toString()
        ? {
          ...el,
          invalid: false,
          ans: options[selectedIndex].innerHTML,
        }
        : el
    );
    this.setState({ answerValues: answerValues });
    // console.log('sel', event.target.name, event.target.value, options[selectedIndex].innerHTML, this.state.answerValues)
    setTimeout(() => {
      this.setQuestions(this.state.questions);
    }, 500);
  }

  onNumberChange(event) {
    let nm = event.target.name,
      vl = event.target.value;
    if (vl !== '' && vl > 0 && vl < 9999) {
      let answerValues = this.state.answerValues.map(el =>
        '' + el.id === nm.toString() ? { ...el, invalid: false, ans: vl } : el
      );
      this.setState({ answerValues: answerValues });
    } else {
      let answerValues = this.state.answerValues.map(el =>
        '' + el.id === nm.toString() ? { ...el, invalid: true, ans: '' } : el
      );
      this.setState({ answerValues: answerValues });
    }
    // console.log('txt', nm, vl.trim(), this.state.answerValues);
    setTimeout(() => {
      this.setQuestions(this.state.questions);
    }, 500);
  }

  handleSubmit(event) {
    event.preventDefault();
    let isFormValid = true;
    let answerValues = this.state.answerValues.map(el => {
      if (el.ans === '') {
        isFormValid = false;
      }
      return el.ans === '' ? { ...el, invalid: true } : el;
    });
    this.setState({ answerValues: answerValues });
    console.log(
      'handleSubmit',
      this.state.answerValues,
      'isFormValid',
      isFormValid
    );
    setTimeout(() => {
      this.setQuestions(this.state.questions);
    }, 500);

    if (isFormValid && this.state.usrID != 0) {
      //coreService.loaderVisible.next(true);
      this.setState({ loader: true });
      let QAset = this.state.answerValues.map(val => {
        return { questionId: String(val.id), answer: val.ans };
      });
      let dataToSend = {
        userId: String(this.state.usrID),
        createdBy: this.state.emailID,
        userEmail: this.state.emailID,
        questionAns: QAset,
      };
      console.log('dataToSend', dataToSend);

      factoryService
        .submitSPSSrequest(dataToSend, this.state.jwtToken)
        .then(result => {
          //coreService.loaderVisible.next(false);
          this.setState({ loader: false });
          console.log('res', result);
          if (typeof result.userId != 'undefined' && result.userId > 0) {
            this.setState({
              alertMsg:
                'Thank you, your request has been submitted successfully. We will inform you via email once the request has been processed.',
              alertType: 'Success',
              showRedirectAlert: true,
            });
          } else {
            this.setState({
              alertMsg:
                'Error occurred while processing your request! Please try again later.',
              alertType: 'Error',
              showAlert: true,
            });
          }
        })
        .catch(error => {
          //coreService.loaderVisible.next(false);
          this.setState({ loader: false });
          console.log(error);
          this.setState({
            alertMsg: 'Something went wrong. Please try again.',
            alertType: 'Error',
            showAlert: true,
          });
        });
    }
  }

  setQuestions(data) {
    if (data != null && typeof data != 'undefined') {
      if (data.length > 0) {
        let tempArray = [];
        // console.log('sada2', data, this.state.answerValues);
        tempArray = data.map((val, idx) => {
          if (val.type == 'Text') {
            return (
              <div key={idx} style={{ marginBottom: '2rem' }}>
                <TextInput
                  key={idx}
                  id={'id' + idx}
                  invalidText="Please enter your response"
                  invalid={this.state.answerValues[idx].invalid}
                  name={val.id}
                  onChange={e => this.onTextChange(e)}
                  labelText={val.question}
                  placeholder="Enter your response"
                  maxLength="200"
                />
              </div>
            );
          } else if (val.type == 'Text area') {
            return (
              <div key={idx} style={{ marginBottom: '2rem' }}>
                <TextArea
                  cols={50}
                  rows={4}
                  key={idx}
                  id={'id' + idx}
                  invalidText="Please enter your response"
                  invalid={this.state.answerValues[idx].invalid}
                  name={val.id}
                  onChange={e => this.onTextChange(e)}
                  labelText={val.question}
                  placeholder="Enter your response"
                  maxLength="1024"
                />
              </div>
            );
          } else if (val.type == 'Numeric') {
            return (
              <div key={idx} style={{ marginBottom: '2rem' }}>
                <NumberInput
                  key={idx}
                  id={'id' + idx}
                  invalidText="Please enter valid response"
                  invalid={this.state.answerValues[idx].invalid}
                  name={val.id}
                  onChange={e => this.onNumberChange(e)}
                  onClick={e => this.onNumberChange(e)}
                  label={val.question}
                  placeholder="Enter your response"
                  max={9999}
                  min={0}
                />
              </div>
            );
          } else if (val.type == 'Option') {
            return (
              <div key={idx} style={{ marginBottom: '2rem' }}>
                <Select
                  key={idx}
                  defaultValue="placeholder-item"
                  invalidText="Please choose an option"
                  invalid={this.state.answerValues[idx].invalid}
                  id={'id' + idx}
                  name={val.id}
                  onChange={this.onSelectTypeChange}
                  labelText={val.question}>
                  <SelectItem
                    disabled
                    hidden
                    value="placeholder-item"
                    text="Choose an option"
                  />
                  {this.state.course.map(op1 => (
                    <SelectItem
                      text={op1.courseCategory}
                      value={op1.id}
                      key={op1.id}
                    />
                  ))}
                </Select>
              </div>
            );
          } else if (val.type == 'Discipline') {
            return (
              <div key={idx} style={{ marginBottom: '2rem' }}>
                <Select
                  key={idx}
                  defaultValue="placeholder-item"
                  invalidText="Please choose an option"
                  invalid={this.state.answerValues[idx].invalid}
                  id={'id' + idx}
                  name={val.id}
                  onChange={this.onSelectTypeChange}
                  labelText={val.question}>
                  <SelectItem
                    disabled
                    hidden
                    value="placeholder-item"
                    text="Choose an option"
                  />
                  {this.state.discipline.map(op3 => (
                    <SelectItem text={op3.code} value={op3.id} key={op3.id} />
                  ))}
                </Select>
              </div>
            );
          } else if (val.type == 'Yes/No') {
            return (
              <div key={idx} style={{ marginBottom: '2rem' }}>
                <Select
                  key={idx}
                  defaultValue="placeholder-item"
                  invalidText="Please choose an option"
                  invalid={this.state.answerValues[idx].invalid}
                  id={'id' + idx}
                  name={val.id}
                  onChange={this.onSelectTypeChange}
                  labelText={val.question}>
                  <SelectItem
                    disabled
                    hidden
                    value="placeholder-item"
                    text="Choose an option"
                  />
                  {this.state.yesNo.map(op2 => (
                    <SelectItem text={op2.option} value={op2.id} key={op2.id} />
                  ))}
                </Select>
              </div>
            );
          }
        });
        this.setState({ questionnaireData: tempArray });
      }
    }
  }

  disableQuestions(data) {
    if (data != null && typeof data != 'undefined') {
      if (data.length > 0) {
        let tempArray = [];
        tempArray = data.map((val, idx) => {
          return (
            <div key={idx} style={{ marginBottom: '2rem' }}>
              <TextInput
                key={idx}
                id={'id' + idx}
                invalidText="Please enter your response"
                invalid={false}
                name={val.id}
                labelText={val.question}
                placeholder="Enter your response"
                disabled
              />
            </div>
          );
        });
        this.setState({ questionnaireData: tempArray });
      }
    }
  }

  closeAlert(event) {
    this.setState({ showAlert: false });
  }
  closeAlertAndRedirect(event) {
    this.setState({ showRedirectAlert: false });
    //coreService.openDefaultLink('/topic/data-science', false);
  }

  render() {
    return (
      <div>
        <NormalHeader />
        <section>
          <Theme theme="g10">
            <Grid
              className="invalid-university-page"
              style={{ minHeight: 600 + 'px' }}>
              <Column
                md={8}
                lg={4}
                sm={4}
                className="spss-request__verticalLine">
                <p>
                  <img
                    className="lecture-request__abstractImg"
                    src={
                      'https://swd-images.s3.jp-tok.cloud-object-storage.appdomain.cloud/Data%20science.png'
                    }
                    onError={error => {
                      error.target.src = `${process.env.PUBLIC_URL
                        }/unknownuser.png`;
                    }}
                    alt="SPSS Modeler MAP"
                  />
                </p>
              </Column>
              <Column
                md={8}
                lg={{ span: 8, offset: 4 }}
                sm={4}
                className="spss-request__formGroup">
                <div className="spssMainForm">
                  <form onSubmit={this.handleSubmit} noValidate>
                    <p className="defaultPara customHeader">
                      SPSS Modeler Premium Concurrent License Request
                    </p>
                    <br />
                    <p>
                      Please complete the form below to request your SPSS concurrent machine license. If you require only one SPSS Modeler license please request a single-user license <Link href='https://academic.ibm.com/a2mt/downloads/data_science?cardID=53'>here</Link>. Requests are processed weekly, typically on Wednesday afternoons.
                    </p>
                    <br />
                    <p style={{ paddingBottom: '1em' }}>
                      <span style={{ color: 'red' }}>*</span>
                      All fields provided below are mandatory.
                    </p>
                    <br />
                    <div>
                      {this.state.questionnaireData != null && (
                        <span>{this.state.questionnaireData}</span>
                      )}
                      <br />
                      <Button type="submit" disabled={this.state.emailID == ''}>
                        Submit
                      </Button>
                    </div>
                  </form>
                  <Modal
                    className="customModal"
                    passiveModal={true}
                    preventCloseOnClickOutside={false}
                    open={this.state.showAlert}
                    size="sm"
                    onRequestClose={this.closeAlert}>
                    <div style={{ padding: '1em 0' }}>
                      {this.state.alertType == 'Success' && (
                        <div>
                          <CheckmarkOutline
                            style={{
                              width: '7em',
                              height: '7em',
                              color: 'green',
                            }}
                          />
                        </div>
                      )}
                      {this.state.alertType == 'Warning' && (
                        <div>
                          <WarningAlt
                            style={{
                              width: '7em',
                              height: '7em',
                              color: 'orange',
                            }}
                          />
                        </div>
                      )}
                      {this.state.alertType == 'Error' && (
                        <div>
                          <MisuseOutline
                            style={{
                              width: '7em',
                              height: '7em',
                              color: 'red',
                            }}
                          />
                        </div>
                      )}
                      <div style={{ paddingLeft: '1em' }}>
                        {this.state.alertMsg}
                      </div>
                    </div>
                  </Modal>
                  <Modal
                    className="customModal"
                    passiveModal={true}
                    preventCloseOnClickOutside={false}
                    open={this.state.showRedirectAlert}
                    size="sm"
                    onRequestClose={this.closeAlertAndRedirect}>
                    <div style={{ padding: '1em 0' }}>
                      {this.state.alertType == 'Success' && (
                        <div>
                          <CheckmarkOutline
                            style={{
                              width: '7em',
                              height: '7em',
                              color: 'green',
                            }}
                          />
                        </div>
                      )}
                      {this.state.alertType == 'Warning' && (
                        <div>
                          <WarningAlt
                            style={{
                              width: '7em',
                              height: '7em',
                              color: 'orange',
                            }}
                          />
                        </div>
                      )}
                      {this.state.alertType == 'Error' && (
                        <div>
                          <MisuseOutline
                            style={{
                              width: '7em',
                              height: '7em',
                              color: 'red',
                            }}
                          />
                        </div>
                      )}
                      <div style={{ paddingLeft: '1em' }}>
                        {this.state.alertMsg}
                      </div>
                    </div>
                  </Modal>
                </div>
              </Column>
            </Grid>
            <br />
            <br />
            <br />
          </Theme>
        </section>
        {this.state.loader && <Loading />}
      </div>
    );
  }
}
