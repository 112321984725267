import axios from 'axios';
import Cookies from 'universal-cookie';

const fCookies = new Cookies();

let waitForCookie = function(cookieName, interval = 100) {
  return new Promise(resolve => {
    const checkCookie = async () => {
      const cookieValue = fCookies.get(cookieName);
      if (cookieValue) {
        resolve(cookieValue);
      } else {
        setTimeout(checkCookie, interval);
      }
    };
    checkCookie();
  });
};

async function waitForSession(sessionName) {
  return new Promise(resolve => {
    const intervalId = setInterval(() => {
      const Session_Value = sessionStorage.getItem(sessionName);
      if (Session_Value) {
        clearInterval(intervalId);
        resolve(Session_Value);
      }
    }, 100); // Check every 100 milliseconds
  });
}

export const factoryService = {
  source_a2mt: 'https://academic.ibm.com/a2mt/',
  Xaccess: fCookies.get('AppAccess'),
  // source_sbsw: 'https://sbsw-content-prod.17f48735.public.multi-containers.ibm.com/',

  // Function to check for the cookie using async/await

  async getAppID() {
    const url = this.source_a2mt + 'services/user-details/getAppId';
    const markup = 'Open_API';
    try {
      const res = await axios.get(url);
      console.log(res.data);
      if (typeof res.data.appId !== 'undefined') {
        if (
          typeof res.headers['salt1'] !== 'undefined' &&
          typeof res.headers['salt2'] !== 'undefined'
        ) {
          let encoded = this.decodeAppID(
            res.data.appId,
            res.headers['salt2'],
            markup
          );
          let decoded = this.decodeAppID(encoded, res.headers['salt1'], markup);
          console.log(decoded);
          return decoded;
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      return error;
    }

    // return axios.get(url)
    //   .then(res=> {
    //     console.log('APP id --------------------',res.data.appId)
    //     if(typeof res.data.appId !== "undefined") {
    //       if (typeof res.headers['salt1'] !== 'undefined' && typeof res.headers['salt2'] !== 'undefined') {

    //         let encoded =  this.decodeAppID(res.data.appId, res.headers['salt2'], markup);
    //         let decoded =  this.decodeAppID(encoded, res.headers['salt1'], markup);
    //         console.log(decoded)
    //         return decoded;

    //       }
    //     }
    //   })
    //   .catch(error=> { return error; });
  },

  async getUserDetails(data) {
    let access = await waitForCookie('AppAccess');
    console.log('getUserDetails', data, '-----', access);
    try {
      const url =
        this.source_a2mt + 'services/user-details/getUserDetails/' + data;
      let res = await axios.get(url, {
        headers: { 'X-access': access, 'Content-Type': 'application/json' },
        resolveWithFullResponse: true,
      });
      return res;
    } catch (error) {
      return error;
    }
  },

  // async getUserDetails(data) {
  //   let access=await waitForCookie('AppAccess')
  //   const url =this.source_a2mt + 'services/user-details/getUserDetails/' + data;
  //   return axios.get(url, {
  //       headers: {
  //         //'X-access': fCookies.get('AppAccess'),
  //         'X-access':access,
  //         'Content-Type': 'application/json',
  //       },
  //     })
  //     .then(res => {
  //       return res;
  //     })
  //     .catch(error => {
  //       return error;
  //     });
  // },
  //modified code
  async getInsttnDetails(data) {
    let appaccess = await waitForCookie('AppAccess');
    console.log('getInsttnDetails', data, appaccess);
    const url =
      this.source_a2mt + 'services/user-details/getInsttnDetails/' + data;
    try {
      let res = await axios.get(url, {
        //headers: { Jwtauth: tokn, 'Content-Type': 'application/json' },
        headers: {
          'X-access': appaccess,
          'Content-Type': 'application/json',
        },
        resolveWithFullResponse: true,
      });
      return res;
    } catch (error) {
      return error;
    }
  },

  // async getInsttnDetails(data) {
  //   let appaccess=await waitForCookie('AppAccess')
  //   const url =
  //     this.source_a2mt + 'services/user-details/getInsttnDetails/' + data;
  //   return axios.get(url, {
  //       //headers: { Jwtauth: tokn, 'Content-Type': 'application/json' },
  //       headers: {
  //         'X-access': appaccess,
  //         'Content-Type': 'application/json',
  //       },
  //     })
  //     .then(res => {
  //       return res.data;
  //     })
  //     .catch(error => {
  //       return error;
  //     });
  // },

  decodeData(response, uuid) {
    let salt = '',
      decodedData = null;
    if (typeof response.headers['salt'] != 'undefined') {
      salt = window.btoa(response.headers['salt']);
    } else {
      console.log('response.headers', response.headers);
    }
    if (typeof response.data.userData != 'undefined') {
      let uid = window.btoa(uuid);
      let tempDat = response.data.userData;
      let dt = tempDat.substring(salt.length, tempDat.lastIndexOf(uid));
      decodedData = JSON.parse(decodeURIComponent(escape(window.atob(dt))));
    }
    return decodedData;
  },

  decodeAppID(appData, variableNm, secret) {
    let salt = window.btoa(variableNm);
    let uid = window.btoa(secret);
    let dt = appData.substring(salt.length, appData.lastIndexOf(uid));
    return window.atob(dt);
  },

  validateLectureData(data) {
    let newData = data.filter(el => {
      if (
        typeof el.abstracts === 'undefined' ||
        typeof el.abstracts[0] === 'undefined' ||
        el.topics === null ||
        el.topics === '' ||
        el.country === null ||
        el.country === '' ||
        el.duration === null ||
        el.duration === '' ||
        el.timesGiven === null ||
        el.timesGiven === '' ||
        el.travelDeliveryOptions === null ||
        el.travelDeliveryOptions === '' ||
        el.email === null ||
        el.email === ''
      )
        return false;
      else return true;
    });
    return newData;
  },

  createFilters(data) {
    let filterSubset = [];
    let filterTop = [],
      filterLan = [],
      filterDur = [],
      filterLoc = [],
      filterTra = [];
    data.map(val => {
      filterTop.push(...val.topics.split(',').map(v => v.trim()));
      if (typeof val.additionalLanguages != 'undefined') {
        filterLan.push(...val.additionalLanguages);
      }
      if (val.abstracts[0].lectureLanguage.toLowerCase() === 'english') {
        filterLan.push(val.abstracts[0].lectureLanguage);
      }
      filterDur.push(...val.duration.split(',').map(v => v.trim()));
      filterLoc.push(val.country);
      if (typeof val.travelDeliveryOptions != 'undefined') {
        filterTra.push(
          ...val.travelDeliveryOptions.split(',').map(v => v.trim())
        );
      }
    });

    filterTop = filterTop.sort().reduce((accumulator, value) => {
      return { ...accumulator, [value]: (accumulator[value] || 0) + 1 };
    }, {});
    filterLan = filterLan.sort().reduce((accumulator, value) => {
      return { ...accumulator, [value]: (accumulator[value] || 0) + 1 };
    }, {});
    filterDur = filterDur.sort().reduce((accumulator, value) => {
      return { ...accumulator, [value]: (accumulator[value] || 0) + 1 };
    }, {});
    filterLoc = filterLoc.sort().reduce((accumulator, value) => {
      return { ...accumulator, [value]: (accumulator[value] || 0) + 1 };
    }, {});
    filterTra = filterTra.sort().reduce((accumulator, value) => {
      return { ...accumulator, [value]: (accumulator[value] || 0) + 1 };
    }, {});
    console.log(
      'unfiltered',
      filterTop,
      filterLan,
      filterDur,
      filterLoc,
      filterTra
    );

    filterSubset.push(
      {
        name: 'Lecture topic',
        id: 'topics',
        values: Object.entries(filterTop),
      },
      {
        name: 'Lecture language',
        id: 'additionalLanguages',
        values: Object.entries(filterLan),
      },
      {
        name: 'Lecture duration',
        id: 'duration',
        values: Object.entries(filterDur),
      },
      {
        name: 'Speaker location',
        id: 'country',
        values: Object.entries(filterLoc),
      },
      {
        name: 'Speaker travel',
        id: 'travelDeliveryOptions',
        values: Object.entries(filterTra),
      }
    );
    return filterSubset;
  },

  removeParams(link) {
    const oldURL = link;
    let index = 0;
    let newURL = oldURL;
    index = oldURL.indexOf('?');
    if (index === -1) {
      index = oldURL.indexOf('#');
    }
    if (index !== -1) {
      newURL = oldURL.substring(0, index);
    }
    return newURL;
  },

  setSession(uuid) {
    sessionStorage.setItem('academics', uuid);
  },

  // async getSession() {
  //   let session_academic= await waitForSession('academics');
  //   console.log('session_academic',session_academic);
  //   //if (sessionStorage.getItem('academics') !== 'undefined' && sessionStorage.getItem('academics') !== null )
  //   if (session_academic!== 'undefined' && session_academic !== null)
  //     {
  //     return session_academic;
  //   } else {
  //     return false;
  //   }
  // },
  async getGenSession(session_name) {
    let session_val = await waitForSession(session_name);
    console.log(session_name, session_val);
    //if (sessionStorage.getItem('academics') !== 'undefined' && sessionStorage.getItem('academics') !== null )
    if (session_val !== 'undefined' && session_val !== null) {
      return session_val;
    } else {
      return false;
    }
  },
  getSession() {
    if (
      sessionStorage.getItem('academics') !== 'undefined' &&
      sessionStorage.getItem('academics') !== null
    ) {
      return sessionStorage.getItem('academics');
    } else {
      return false;
    }
  },

  destroySession() {
    sessionStorage.removeItem('academics');
  },

  setTechCode(data) {
    sessionStorage.setItem('tCode', data);
  },

  getTechCode() {
    if (
      sessionStorage.getItem('tCode') !== 'undefined' &&
      sessionStorage.getItem('tCode') !== null
    ) {
      return sessionStorage.getItem('tCode');
    } else {
      return false;
    }
  },

  destroyTechCode() {
    sessionStorage.removeItem('tCode');
  },

  getAuthToken(data) {
    const url = this.source_a2mt + 'services/user-details/getJwt';
    return axios
      .get(url, {
        headers: { emailId: data },
      })
      .then(res => {
        return res;
      })
      .catch(error => {
        return error;
      });
  },

  getJWT(email) {
    this.getAuthToken(email)
      .then(tokn => {
        console.log(
          'Head Data',
          tokn.data,
          tokn.headers['jwt-auth'],
          tokn.headers['refresh']
        );
        if (typeof tokn.headers['jwt-auth'] != 'undefined') {
          let token = tokn.headers['jwt-auth'];
          sessionStorage.setItem('jwtAuth_ID', token);
          sessionStorage.setItem('refreshT_ID', tokn.headers['refresh']);
        }
      })
      .catch(error => {
        console.log('Error', error);
      });
  },

  getNewToken(data) {
    const url = this.source_a2mt + 'services/user-details/checkExpiration';
    return axios
      .get(url, {
        headers: { JwtExpiration: data },
      })
      .then(res => {
        return res;
      })
      .catch(error => {
        return error;
      });
  },

  getDSW(data, tokn) {
    const url = this.source_a2mt + 'services/user-details/DSWSoapEnv/' + data;
    return axios
      .get(url, {
        headers: { Jwtauth: tokn, 'Content-Type': 'application/json' },
      })
      .then(res => {
        return res.data;
      })
      .catch(error => {
        return error;
      });
  },

  async callDSW(UserID, DSW_PartNo, linkName, assetName) {
    await this.getDSW(UserID, sessionStorage.getItem('jwtAuth_ID'))
      .then(result => {
        console.log('DSW Result :: ' + JSON.stringify(result));
        if (result != null && result != '') {
          if (result.success == true) {
            const pNo = String(DSW_PartNo);
            let partNumber = '&partNumber=' + pNo;
            if (pNo == 'SEARCH' || pNo == '') {
              partNumber = '';
            }
            const redirectURL = encodeURI(result.redirectURL + partNumber);
            window.open(redirectURL, '_blank');
            this.saveActivity(UserID, assetName, linkName);
            return true;
          } else return false;
        } else return false;
      })
      .catch(error => {
        console.log('DSW Error :: ', error);
        return false;
      });
  },

  getPromoCode(data, promoCodeType, tempID, tokn) {
    const url =
      this.source_a2mt +
      'services/user-details/getPromoCode/' +
      data +
      '/' +
      promoCodeType +
      '/' +
      tempID;
    return axios
      .get(url, {
        headers: { Jwtauth: tokn, 'Content-Type': 'application/json' },
        responseType: 'text',
      })
      .then(res => {
        return res.data;
      })
      .catch(error => {
        return error;
      });
  },

  sendPromoCodeRequestEmail(data, emailId, tempID, tokn) {
    const url =
      this.source_a2mt +
      'services/user-details/sendEmail/' +
      data +
      '/' +
      emailId +
      '/' +
      tempID;
    return axios
      .get(url, {
        headers: { Jwtauth: tokn, 'Content-Type': 'application/json' },
        responseType: 'text',
      })
      .then(res => {
        return res.data;
      })
      .catch(error => {
        return error;
      });
  },

  saveActivity(userID, assetName, linkName) {
    const data = {
      uuid: userID,
      assetName: assetName,
      linkName: linkName,
    };
    console.log('User Activity Data ::', data);
    this.saveUserActivity(data, sessionStorage.getItem('jwtAuth_ID'))
      .then(res => {
        console.log('User Activity Result :: ' + JSON.stringify(res));
        if (res.success === true) {
          console.log('User activity is saved');
        } else {
          return false;
        }
      })
      .catch(error => {
        console.log('Save Activity Error :: ' + JSON.stringify(error));
        return false;
      });
  },

  saveUserActivity(data, tokn) {
    const url = this.source_a2mt + 'services/user-details/trackAssetDownload';
    return axios
      .post(url, data, {
        headers: { Jwtauth: tokn, 'Content-Type': 'application/json' },
      })
      .then(res => {
        return res.data;
      })
      .catch(error => {
        return error;
      });
  },

  async getGuestLectures() {
    let appaccess = await waitForCookie('AppAccess');
    let url = this.source_a2mt + 'services/user-details/getLecturesList';
    return axios
      .get(url, {
        headers: {
          'X-access': appaccess,
          'Content-Type': 'application/json',
        },
      })
      .then(res => res)
      .catch(error => error);
  },

  async getGuestLectureWithId(lecId) {
    let appaccess = await waitForCookie('AppAccess');
    let url =
      this.source_a2mt + 'services/user-details/getLectureById/' + lecId;
    return axios
      .get(url, {
        headers: {
          'X-access': appaccess,
          'Content-Type': 'application/json',
        },
      })
      .then(res => res)
      .catch(error => error);
  },

  async fetchLecQuestions(tokn) {
    let url = this.source_a2mt + 'services/user-details/getLectureQuestions';
    console.log('fetchLecQuestions_tokn', tokn);
    // let res=await axios.get(url,{headers: { Jwtauth: tokn, 'Content-Type': 'application/json' }});
    //return res.data;
    // await axios.get(url, {
    //     headers: { Jwtauth: tokn, 'Content-Type': 'application/json' },
    //   }).then(res =>{
    //     return res.data;
    //   }).catch(error =>{return error;} );
    try {
      let res = await axios.get(url, {
        headers: {
          Jwtauth: tokn,
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
        },
        resolveWithFullResponse: true,
      });
      if (res.status === 200) {
        console.log('fetchLecQuestions', res);
      }
      return res.data;
    } catch (err) {
      console.error(err);
    }
  },

  submitLecRequest(data, tokn) {
    const url = this.source_a2mt + 'services/user-details/reqLecture';
    return axios
      .post(url, data, {
        headers: { Jwtauth: tokn, 'Content-Type': 'application/json' },
      })
      .then(res => {
        return res.data;
      })
      .catch(error => {
        return error;
      });
  },

  async updateTimesGiven(lecId) {
    let appaccess = await waitForCookie('AppAccess');
    let data = {};
    let url =
      this.source_a2mt + 'services/user-details/updateTimesGivenCount/' + lecId;
    return axios
      .post(url, data, {
        headers: {
          'X-access': appaccess,
          'Content-Type': 'application/json',
        },
      })
      .then(res => res)
      .catch(error => error);
  },

  async getContent(contentId) {
    let appaccess = await waitForCookie('AppAccess');
    let data = { route: contentId, token: this.getTechCode() };
    let url = this.source_a2mt + 'services/user-details/getContent';
    return axios
      .post(url, data, {
        headers: {
          'X-access': appaccess,
          'Content-Type': 'application/json',
        },
      })
      .then(res => res)
      .catch(error => error);
  },

  getAllTechnology() {
    let apiCall = 'getTchnology';
    return this.getContent(apiCall);
  },

  getTechnologyById(ID) {
    let apiCall = 'getTchnology/ID/' + ID;
    return this.getContent(apiCall);
  },

  getTechnologyCards(ID) {
    let apiCall = 'getTchnologyCourse/TechnologyId/' + ID;
    return this.getContent(apiCall);
  },

  getTechnologyLinks() {
    let apiCall = 'getTchnologyCourseLinks';
    return this.getContent(apiCall);
  },

  getPageMessages() {
    let apiCall = 'getTchnologyPageMessages';
    return this.getContent(apiCall);
  },

  getPromoCodeTemplate() {
    let apiCall = 'getTchnologyEmailTemplate';
    return this.getContent(apiCall);
  },

  // getAllTechnology() {
  //   let url = this.source_sbsw + 'getTchnology';
  //   return axios
  //     .get(url, {
  //       headers: {
  //         'x-access-token': this.getTechCode(),
  //         'Content-Type': 'application/json',
  //       },
  //     })
  //     .then(res => res)
  //     .catch(error => error);
  // },

  // getTechnologyById(ID) {
  //   let url = this.source_sbsw + 'getTchnology/ID/' + ID;
  //   return axios
  //     .get(url, {
  //       headers: {
  //         'x-access-token': this.getTechCode(),
  //         'Content-Type': 'application/json',
  //       },
  //     })
  //     .then(res => res)
  //     .catch(error => error);
  // },

  // getTechnologyCards(ID) {
  //   let url = this.source_sbsw + 'getTchnologyCourse/TechnologyId/' + ID;
  //   return axios
  //     .get(url, {
  //       headers: {
  //         'x-access-token': this.getTechCode(),
  //         'Content-Type': 'application/json',
  //       },
  //     })
  //     .then(res => res)
  //     .catch(error => error);
  // },

  // getTechnologyLinks() {
  //   let url = this.source_sbsw + 'getTchnologyCourseLinks';
  //   return axios
  //     .get(url, {
  //       headers: {
  //         'x-access-token': this.getTechCode(),
  //         'Content-Type': 'application/json',
  //       },
  //     })
  //     .then(res => res)
  //     .catch(error => error);
  // },

  // getPageMessages() {
  //   let url = this.source_sbsw + 'getTchnologyPageMessages';
  //   return axios
  //     .get(url, {
  //       headers: {
  //         'x-access-token': this.getTechCode(),
  //         'Content-Type': 'application/json',
  //       },
  //     })
  //     .then(res => res)
  //     .catch(error => error);
  // },

  // getPromoCodeTemplate() {
  //   let url = this.source_sbsw + 'getTchnologyEmailTemplate';
  //   return axios
  //     .get(url, {
  //       headers: {
  //         'x-access-token': this.getTechCode(),
  //         'Content-Type': 'application/json',
  //       },
  //     })
  //     .then(res => res)
  //     .catch(error => error);
  // },

  getEventDetails(ID) {
    const url = this.source_a2mt + 'services/user-details/showWorkshop/' + ID;
    return axios
      .get(url, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then(res => res.data)
      .catch(error => error);
  },

  submitEventRequest(data, tokn) {
    const url = this.source_a2mt + 'services/user-details/addUserWorkshop';
    return axios
      .post(url, data, {
        headers: { Jwtauth: tokn, 'Content-Type': 'application/json' },
      })
      .then(res => {
        return res;
      })
      .catch(error => {
        return error;
      });
  },

  saveAgreement(data, tokn) {
    const url = this.source_a2mt + 'services/user-details/saveAgreement';
    return axios
      .post(url, data, {
        headers: { Jwtauth: tokn, 'Content-Type': 'application/json' },
      })
      .then(res => {
        return res;
      })
      .catch(error => {
        return error;
      });
  },

  async updRegDateAftURX(ID, url) {
    let appaccess = await waitForCookie('AppAccess');
    return axios
      .post(this.source_a2mt + 'services/user-details/updRegDateAftURX', ID, {
        headers: {
          'Content-Type': 'application/json',
          'X-access': appaccess,
          redirectTo: url,
        },
      })
      .then(res => res)
      .catch(error => error);
  },

  ////SPSS-MAP Starts
  fetchQuestions(tokn) {
    let url = this.source_a2mt + 'services/user-details/getQuestions';
    return axios
      .get(url, {
        headers: { Jwtauth: tokn, 'Content-Type': 'application/json' },
      })
      .then(res => res.data)
      .catch(error => error);
  },
  fetchYesNo(tokn) {
    let url = this.source_a2mt + 'services/user-details/getSpssYesNo';
    return axios
      .get(url, {
        headers: { Jwtauth: tokn, 'Content-Type': 'application/json' },
      })
      .then(res => res.data)
      .catch(error => error);
  },
  fetchCourse(tokn) {
    let url = this.source_a2mt + 'services/user-details/getspssCourse';
    return axios
      .get(url, {
        headers: { Jwtauth: tokn, 'Content-Type': 'application/json' },
      })
      .then(res => res.data)
      .catch(error => error);
  },
  fetchDiscipline(tokn) {
    let url = this.source_a2mt + 'services/user-details/getDiscipline';
    return axios
      .get(url, {
        headers: { Jwtauth: tokn, 'Content-Type': 'application/json' },
      })
      .then(res => res.data)
      .catch(error => error);
  },
  submitSPSSrequest(data, tokn) {
    const url = this.source_a2mt + 'services/user-details/addSPSSrequest';
    return axios
      .post(url, data, {
        headers: { Jwtauth: tokn, 'Content-Type': 'application/json' },
      })
      .then(res => {
        return res.data;
      })
      .catch(error => {
        return error;
      });
  },
  ////SPSS-MAP Ends
};
export default factoryService;
