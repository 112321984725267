import React, { useEffect, useState } from 'react';
import {
  Grid,
  Column,
  Theme,
  Section,
  Search,
  Button,
  Checkbox,
  Accordion,
  AccordionItem,
  Loading
} from '@carbon/react';
import { Education, Translate, Time, Launch } from '@carbon/icons-react';
import factoryService from '../../services/factory-service';
import LectureAbstract from './LectureAbstract';
import queryString from 'query-string';
import NormalHeader from '../../components/NormalHeader/NormalHeader';
import AsyncImage from './AsyncImage';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const GuestLecture = () => {
  const [originalJSON, setOriginalJSON] = useState(null);
  const [filteredJSON, setFilteredJSON] = useState([]);
  const [filterActive, setFilterActive] = useState(false);
  const [filtersApplied, setFiltersApplied] = useState([]);
  const [isAbstractPageVisible, setAbstractPageVisible] = useState(false);
  const [selectedLecture, setSelectedLecture] = useState(0);
  const [rightNav, setRightNav] = useState(null);
  const [leftNav, setLeftNav] = useState(null);
  const [searchError, setSearchError] = useState('');
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    let params = queryString.parse(window.location.search);
    console.log('paramsGetter', params);
    setShowLoader(true);

    if (typeof params['lecId'] !== 'undefined') {
      setAbstractPageVisible(true);
      setSelectedLecture(params['lecId']);
      setShowLoader(false);
    }
    else {
      setAbstractPageVisible(false);
      setSelectedLecture(0);
      const fetchData = async () => {
        const value = await factoryService.getGuestLectures();
        const newData = await factoryService.validateLectureData(factoryService.decodeData(value,'gupai@us.ibm.com'));
        
        setShowLoader(false);
        setOriginalJSON(newData); setFilteredJSON(newData);
        setLectureData(newData);
      }

      if (typeof cookies.get('AppAccess') !== 'string') {
        setTimeout(() => { fetchData().catch(console.error); }, 3000);
      } else {
        fetchData().catch(console.error);
      }
    }
  }, []);

  const setLectureData = async(newData) => {
    console.log('newData', newData);
    setRightNav(newData);
    const filterData = await factoryService.createFilters(newData);
    console.log('filterData', filterData);
    setLeftNav(filterData);
  };

  useEffect(() => { console.log('rightNav', rightNav) }, [rightNav]);
  useEffect(() => { console.log('leftNav', leftNav) }, [leftNav]);

  const searchLectures = (event) => {
    let x = event.target.value.trim().toLowerCase();
		let kcode = event.keyCode ? event.keyCode : event.which;
    let tempData = originalJSON;
    if (kcode === 13 && x !== '') {
			let tempArr = [];
			console.log(x);
			tempData.forEach(value => {
				if (value.ambassadorBio !== null) {
					if (
						value.abstracts[0].abstract.toLowerCase().search(x) !==
							-1 ||
						value.ambassadorBio.toLowerCase().search(x) !== -1 ||
						value.abstracts[0].guestLectureTitle
							.toLowerCase()
							.search(x) !== -1 ||
						value.guestLecturerName.toLowerCase().search(x) !== -1
					) {
						tempArr.push(value);
					}
				} else {
					if (
						value.abstracts[0].abstract.toLowerCase().search(x) !==
							-1 ||
						value.abstracts[0].guestLectureTitle
							.toLowerCase()
							.search(x) !== -1 ||
						value.guestLecturerName.toLowerCase().search(x) !== -1
					) {
						tempArr.push(value);
					}
				}
			});
			console.log(tempArr);
      if (tempArr.length !== 0) {
        setFilterActive(true);
        setFilteredJSON(tempArr);
        setSearchError('');
				setLectureData(tempArr);
			} else {
        setFilterActive(false);
        searchError('No results found!');
			}
    }
  };

  const clearLectures = () => {
    setFilterActive(false);
    setSearchError(false);
    setFilteredJSON(originalJSON);
    setLectureData(originalJSON);
  };

  const filterActivated = (event) => {
    let fArray = []; 
    let array = [];
    let copyFiltersApplied = filtersApplied;
    let copyFilterActive = filterActive;
    let copyFilteredJSON = filteredJSON;
		if (event.target.checked) {
      array = [...filtersApplied, event.target.id];
			array = [...new Set(array)];
      copyFiltersApplied = array;
      copyFilterActive = false;
			fArray = array.map(item =>
				filteringArray(item, filteredJSON)
			);
      copyFilteredJSON = fArray[fArray.length - 1];
    } else {
      array = [...filtersApplied];
			let index = array.indexOf(event.target.id);
			if (index !== -1) {
				array.splice(index, 1);
        copyFiltersApplied = array;
			}
			if (array.length == 0) {
        copyFilterActive = false;
				fArray = [...originalJSON];
        copyFilteredJSON = fArray;
			} else {
				fArray = array.map(item =>
					filteringArray(item, originalJSON)
				);
        copyFilteredJSON = fArray[fArray.length - 1];
			}
    }
    console.log('finalArray', fArray);
    console.log('numberOfFiltersActive & FinalArray', copyFiltersApplied, fArray);
    console.log('filteredJSON', copyFilteredJSON);

		setFiltersApplied(copyFiltersApplied);
    setFilterActive(copyFilterActive);
    setFilteredJSON(copyFilteredJSON);
		setLectureData(copyFilteredJSON);
  };

  const filteringArray = (searchItem, unfilteredArray) => {
		let tempArray = [];
		let key = searchItem.split('_')[0];
		let value = searchItem.split('_')[1];
		unfilteredArray.forEach(val => {
			switch (key) {
				case 'topics':
					val.topics.split(',').map(v => {
						if (v.trim() == value) {
							tempArray.push(val);
						}
					});
					break;
				case 'additionalLanguages':
					if (
						typeof val.additionalLanguages != 'undefined' &&
						value != 'English'
					) {
						val.additionalLanguages.map(v => {
							if (v.trim() == value) {
								tempArray.push(val);
							}
						});
					} else if (value.toLowerCase() == 'english') {
						val.abstracts.map(ln => {
							if (
								ln.lectureLanguage.toLowerCase() ==
								value.toLowerCase()
							) {
								if (tempArray.indexOf(val) == -1)
									tempArray.push(val);
							}
						});
					}
					break;
				case 'duration':
					val.duration.split(',').map(v => {
						if (v.trim() == value) {
							tempArray.push(val);
						}
					});
					break;
				case 'country':
					if (val.country.trim() == value) {
						tempArray.push(val);
					}
					break;
				case 'travelDeliveryOptions':
					val.travelDeliveryOptions.split(',').map(v => {
						if (v.trim() == value) {
							tempArray.push(val);
						}
					});
					break;
			}
		});
		return tempArray;
	};

  useEffect(()=>{
    if (filtersApplied.length > 0) {
			let filApp = filtersApplied;
			setTimeout(() => {
				let cb = document.querySelectorAll("input[type='checkbox']");
				cb.forEach(elem => {
					elem.checked = false;
				});
				filApp.map(fA => {
					document.getElementById(fA).checked = true;
					console.log('filApp', fA, document.getElementById(fA));
				});
			}, 500);
		}
  }, [filtersApplied]);

  return (
    <Section>
      <NormalHeader></NormalHeader>
      {isAbstractPageVisible === false && (
        <Theme theme="g10">
          <Grid className="guest-lecture">
            <Column md={4} lg={8} sm={4}>
              <h1 className="guest-lecture__heading">
                Guest Lectures
              </h1>
              <br /><br /><br />
              <br /><br /><br />
              <br /><br /><br />
              <p className="guest-lecture__p">
                Explore resources that are available to <br />
                accredited institutions.
              </p>
            </Column>
            <Column md={4} lg={{ span: 8, offset: 8 }} sm={4}>
              <img
                className="guest-lecture__bannerImg"
                src={'https://swd-images.s3.jp-tok.cloud-object-storage.appdomain.cloud/lecture.png'}
                alt="Guest Lecture Banner"
              />
            </Column>
          </Grid>
        </Theme>
      )}
      <Theme theme="white">
        {isAbstractPageVisible === false ? (
          <Grid className="guest-lecture">
            <Column md={2} lg={4} sm={4}>
              <p className="guest-lecture__filterHeading">Lecture filters</p>
              {leftNav != null ?
                leftNav.map(item => {
                  return (
                    <Accordion>
                      <AccordionItem title={item.name} open className="guest-lecture__filterTitle">
                        {item.values.map(v => {
                          return <Checkbox id={item.id + '_' + v[0]} labelText={v[0] + ' (' + v[1] + ')'} onClick={e=>filterActivated(e)}/>
                        })}
                      </AccordionItem>
                    </Accordion>
                  )
                })
                : ''}
            </Column>
            <Column md={6} lg={{ span: 12, offset: 4 }} sm={4}>
              <p className="guest-lecture__Searchbox">
                <Search size="lg" placeholder="Search IBM lectures by topic or keywords" labelText="Search" 
                closeButtonLabelText="Clear search input" id="search-1" onKeyDown={searchLectures} onClear={clearLectures}/></p>
              <p className="guest-lecture__resultCount">{rightNav != null ? rightNav.length : '0'} results</p>
              {rightNav != null ?
                rightNav.map(val => {
                  return (
                    <Grid narrow className="guest-lecture__contentGrid" id={val.guestLectureId}>
                      <AsyncImage email={val.email} guestLecturerName={val.guestLecturerName} type={1}/>
                      <Column lg={{ span: 10, offset: 2 }} md={{ span: 3, offset: 1 }} sm={4} className="guest-lecture__content">
                        <p className="guest-lecture__lectureHeading">{val.abstracts[0].guestLectureTitle}</p>
                        <p><Education size={20} className="guest-lecture__attributeIcons"></Education> <span className="guest-lecture__attributeText">Topic: {val.topics}</span></p>
                        <p><Translate size={20} className="guest-lecture__attributeIcons"></Translate> <span className="guest-lecture__attributeText">Languages: {val.abstracts[0].lectureLanguage}{typeof val.additionalLanguages != 'undefined' ? ', ' + val.additionalLanguages : ''}</span></p>
                        <p><Time size={20} className="guest-lecture__attributeIcons"></Time> <span className="guest-lecture__attributeText">Duration: {val.duration}</span></p>
                        <p className="guest-lecture__lectureDescription" dangerouslySetInnerHTML={{ __html: val.abstracts[0].abstract }}></p>
                        <p className="guest-lecture__lectureButton"><Button renderIcon={Launch} iconDescription="Icon Description" id={'button_' + val.guestLectureId}
                          onClick={event => { event.stopPropagation(); let url = factoryService.removeParams(window.location.href); window.location.href = url + '?lecId=' + val.guestLectureId; }}>More information</Button></p>
                      </Column>
                    </Grid>
                  )
                })
                : ''}
            </Column>
          </Grid>
        ) : (<LectureAbstract lecID={selectedLecture}></LectureAbstract>)}
      </Theme>
      <Loading withOverlay={true} description="Active loading indicator" active={showLoader}></Loading>
    </Section>
  )
}

export default GuestLecture;